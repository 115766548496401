@media (min-width:992px) {
    .FooterSection {
        padding: 180px 20px 50px 20px;
        background:linear-gradient(180deg, black 6%, white 18%);
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;
    }
    .LayerOne{
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 300px;
        padding: 0 0 50px 0;
    }
    .FooterOne {
        display: flex;
        flex-direction: column;
        justify-content:left
    }

    .LayerTwo{
        display:flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        width:100%;
    }
    .LayerTwoContainer{
        display: flex;
        justify-content:baseline;
        flex-direction: column;
        flex-wrap: wrap;
        padding:0 30px 0 30px;
    }

    .FooterLink{
        font-size: 1.7rem;
        font-family:Helvetica;
        color: black;
        padding: 5px;
    }
    .FooterLink_Main{
        font-size: 1.7rem;
        font-family:Helvetica;
        color: black;
        padding: 5px;
    }
    .FooterLinkMain{
        font-size: 2.2rem;
        font-family:Helvetica;
        color: black;
        text-decoration: underline;
        padding: 5px;
    }
    
}
@media (max-width:991px) {
    .FooterSection {
        padding: 100px 20px 50px 20px;
        background:linear-gradient(180deg, black 2%, white 6%);
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;
    }
    .LayerOne{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 0 50px 0;
    }
    .FooterOne {
        display: flex;
        flex-direction: column;
        justify-content:left
    }

    .LayerTwo{
        margin: 0 0 200px 0;
        display:flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .LayerTwoContainer{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .FooterLink{
        color:black;
        font-family: "Arial", Sans-serif;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2rem;
    }
    .FooterLink_Main{
        color:black;
        font-family: "Arial", Sans-serif;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
        text-align: center;
    }
    .FooterLinkMain{
        margin:30px 70px;
        color:black;
        font-family: "Arial", Sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2rem;
        text-align: center;
        text-decoration: underline;
    }
    .SocialMediaContainer{
        padding: 25px 0 0 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: center;
    }
    .PrivacyDiv{
        text-align: start;
    }
}