@media (min-width:992px){
    body {
        background:linear-gradient(160deg, #e76203 4%, black 8%);
        background-repeat: no-repeat;
    }
    html {
        scroll-behavior: smooth;
    }
}

@media (max-width: 991px) {
    body {
        background:linear-gradient(160deg, #e76203 2%, black 5%);
        background-repeat: no-repeat;
    }
    html {
        scroll-behavior: smooth;
    }
}