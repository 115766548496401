@media (min-width:992px) {
    .HeroSection {
        padding: 50px 20px 50px 20px;
        margin: 0 0 150px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 100px; 
        background-color: transparent;
        overflow: hidden;
    }
    .ProblemSection {
        padding: 50px 120px 50px 120px;
        margin: 0 0 80px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 150px; 
        background-color: transparent;
        overflow: hidden;
    }
    .ProblemContainer{
        max-width: 800px;
    }
    .SolutionSection{
        padding: 50px 120px 50px 120px;
        margin: 0 0 80px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 150px; 
        background-color: transparent;
        overflow: hidden;
    }
    .ShowFaceSection{
        padding: 50px 120px 50px 120px;
        margin: 0 0 80px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 150px; 
        background-color: transparent;
        overflow: hidden;
    }
    .ShowFaceContainer{
        max-width: 1000px;
    }
    .ShowFaceContainer2{
        text-align: center;
    }
    .QuoteSection{
        padding: 50px 120px 50px 120px;
        margin: 0 0 80px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        background-color: transparent;
        overflow: hidden;
        text-align: center;
    }
    .FinalCTASection {
        padding: 120px 20px 120px 20px;
        margin: 0 0 0 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px; 
        background-color: transparent;
        overflow: hidden;
    }
    .CTABox1 {
        padding: 0 20px 20px 20px;
        background-color: #001120;
        border: 2px solid #e76203;
        max-width: 600px;
        max-height: 600px;
        overflow:auto;
    }
    .CTABox {
        padding: 0 20px 20px 20px;
        background-color: #002347;
        border: 2px solid #e76203;
        max-width: 600px;
        max-height: 600px;
        overflow: auto;
    }

    .FunFact{
        text-align: center;
        margin: 0 0 120px 0;
    }
    h1{
        color:white;
        font-family: helvetica,arial;
        font-size: 3.6rem;
        font-weight: 600;
        margin-bottom: 50px;
    }
    .h2Label {
        color: #e76203;
        font-family: Arial,'Times New Roman';
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 60px;
        margin-bottom: 70px;
    }
    .h2LabelCTA {
        color: White;
        font-family: Arial,'Times New Roman';
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 60px;
        margin-bottom: 70px;
    }
    .h2Quote{
        color: #e76203;
        font-family: Arial,'Times New Roman';
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 100px;
        margin-bottom: 70px;
    }
    .pLabel {
        color: #cbcbcb;
        font-family: Arial,'Times New Roman';
        font-size: 2rem;
        font-weight: 600;
        line-height: 70px;
        margin-bottom: 70px;
    }
    h2{
        color: #cbcbcb;
        font-family: Arial,'Times New Roman';
        font-size: 2rem;
        font-weight: 600;
        line-height: 50px;
        margin-bottom: 70px;
    }
    p{
        color:#cbcbcb;
        font-family: Arial,'Times New Roman';
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .FaceWrap{
        border-radius: 50%;
        overflow: hidden;
        width: 300px;
        height: 300px;
        position:relative;
    }
    .FaceWrap img {
        display: block;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        position: absolute;

    }
    .CTANudge{
        display: inline;
        margin: 5px 15px 0 0;
        font-size: 1.4rem;
        line-height: 50px;
        color: #b2b2b2;
    }
    .MainCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
    .GhostCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: transparent;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:white;
        display: inline;
        cursor: pointer;
    }
    .MobileLogo {
        display: none;
    }
    .RightScrollMenu {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: fixed;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        z-index: 1500;
        width: 125px;
        height: 100vh;
        transition: width 2s ease-in-out;
      }
      
      .RightScrollMenu.expanded {
        width: 100vw;
      }
    .ComputerMainMenu_P {
        color: #dedede;
        font-family: "Arial", Sans-serif;
        text-align: center;
        font-size: 2rem;
        font-weight: 400;
        line-height: 3rem;
    }
      
}
@media (max-width:991px) {
    .HeroSection {
        padding: 10px 20px 10px 20px;
        margin: 20px 0 90px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }
    .FunFact {
        padding: 10px 20px 10px 20px;
        margin: 0 0 25px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }
    .HeroImgMobile {
        display: none;
    }
    .ProblemSection {
        padding: 10px 20px 10px 20px;
        margin: 0 0 90px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }
    .ProblemContainer{
        max-width: 800px;
    }
    .SolutionSection{
        padding: 10px 20px 10px 20px;
        margin: 0 0 90px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }
    .ShowFaceSection{
        padding: 10px 20px 10px 20px;
        margin: 0 0 0 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }
    .ShowFaceContainer{
        margin: 0 0 90px 0;
        max-width: 100%;
    }
    .ShowFaceDiv {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        overflow: hidden;
    }
    .QuoteSection{
        padding: 10px 20px 10px 20px;
        margin: 0 0 70px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }
    .FinalCTASection {
        padding: 10px 20px 10px 20px;
        margin: 0 0 80px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }
    .CTABox1 {
        padding: 0 20px 20px 20px;
        background-color: #001120;
        border: 2px solid #e76203;
        max-width: 700px;
        max-height: 700px;
        overflow:auto;
    }
    .CTABox {
        padding: 0 20px 20px 20px;
        background-color: #002347;
        border: 2px solid #e76203;
        max-width: 700px;
        max-height: 700px;
        overflow: auto;
    }
    h1{
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }
    .h2Label {
        color: #e76203;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }
    .h2LabelCTA {
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3.5rem;
    }
    .h2Quote{
        color: #e76203;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 4rem;
    }
    .pLabel {
        color: #cbcbcb;
        margin: 0 0 70px 0;
        font-family: "Arial", Sans-serif;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.5rem;
    }
    .plabelDescriptor{
        color: #CBCBCB;
        margin: 0 0 50px 0;
        font-family: "Arial", Sans-serif;
        font-size: 2rem;
        font-weight: 400;
        line-height: 3rem;
    }
    h2{
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
        margin: 0 0 70px 0;
    }
    p{
        color:#cbcbcb;
        font-family: Arial,'Times New Roman';
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .FaceWrap{
        border-radius: 50%;
        overflow: hidden;
        width: 300px;
        height: 300px;
        position:relative;
    }
    .FaceWrap img {
        display: block;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        position: absolute;

    }
    .CTANudge{
        display: inline;
        margin: 5px 15px 0 0;
        font-size: 1.4rem;
        line-height: 50px;
        color: #b2b2b2;
    }
    .MainCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
    .GhostCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: transparent;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:white;
        display: inline;
        cursor: pointer;
    }
    .LogoDown {
        padding: 20px 0 0 0;
        margin: 0 0 0 0;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .JohnsonName {
        text-align: end;
    }
    .MobileNavBar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: fixed;
        align-items: center;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
        z-index: 1000;
        width: 100%;
        height: 160px;
    }
    .NavBar_P {
        margin: 0 0 0 0;
        color: #E76203;
        font-family: "Arial", Sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 3rem;
        text-align: center;
    }
    .ModalButton{
        transition: transform 0.5s ease;
    }
    .ModalButton.rotate {
        transform: rotate(180deg);
    }
    .RightScroll{
        display: none;
    }
}
