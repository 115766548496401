@media (min-width: 992px) {
    .CMM_TOP {
        padding: 10px 20px;
        margin: 10px 0 80px;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        overflow: hidden;
    }
    .PRATSMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: 10px 0;
    }
    
    .PRATSMenu a, .PRATSMenu .PRATSDescription {
        flex-basis: 25%;
        text-align: left;
    }
    
    .PRATSMenu img {
        max-width: 100%;
        height: auto;
    }
    
    .PRATSDescription p {
        margin: 0;
    }
    .TextMenu {
        padding: 0 0 20px 0;
        color: #b8b7b7;
        font-family: "Arial", Sans-serif;
        text-align: start;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 3rem;
    }
    .MainCTAPRATS{
        padding: 10px;
        border: 1px solid #1a9599;
        border-radius: 10px;
        background: #1a9599;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
    .DesktopMenuH1 {
        color:#e76203;
        text-align: center;
        font-family: helvetica,arial;
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .DesktopMenuH2 {
        color:#b8b7b7;
        text-align: center;
        font-family: helvetica,arial;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 100px;
    }
    
}
