@media (min-width: 992px){
    .InternshipHero {
            padding: 10px 10px 10px 10px;
            margin: 50px 0 150px 0;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            overflow: hidden;
    }

    .Buttons{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        text-align: left;
        gap: 10px;
    }

    .InternshipMVP {
        padding: 10px 10px 10px 10px;
        margin: 50px 0 150px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        overflow: hidden;
    }

    .InternshipCTA {
        padding: 10px 10px 10px 10px;
        margin: 50px 0 150px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        background-color: transparent;
        overflow: hidden;
    }

    .SubSection {
        padding: 10px;
        margin: 0 0 90px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        vertical-align: baseline;
        gap: 200px;
        min-width: 100%;
    }

    .MainCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
    .GhostCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: transparent;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:white;
        display: inline;
        cursor: pointer;
    }

    .SectionH1 {
        color: #E76203;
        font-family: "Roboto", Sans-serif;
        font-size: 3.6rem;
        font-weight: 600;
    }

    .SectionH2{
        color: #CBCBCB;
        font-family: "Roboto", Sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 50px;
    }

    .CTAH1 {
        color: #CBCBCB;
        font-family: "Roboto", Sans-serif;
        font-size: 3.6rem;
        font-weight: 600;
    }

    .CTAH2 {
        color: #E76203;
        font-family: "Roboto", Sans-serif;
        font-size: 3.6rem;
        font-weight: 600;
    }

    .CTAH3 {
        color: #CBCBCB;
        font-family: "Roboto", Sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 50px;
    }

    .CTANumber {
        color: #000000;
        font-family: "Helvetica", Sans-serif;
        font-size: 200px;
        font-weight: bold;
        -webkit-text-stroke-width: 1px;
        stroke-width: 1px;
        -webkit-text-stroke-color: #E76203;
        stroke: #E76203;
    }

    .InternshipCTABox {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 40%;
    }
    .MobileLogo {
        display: none;
    }
    
}
@media (max-width: 991px){
    .InternshipHero {
            padding: 10px 20px 10px 20px;
            margin: 20px 0 90px 0;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            background-color: transparent;
            overflow: hidden;
    }
    .IH_Div{
        text-align: start;
    }
    .IH_Div_One{
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }
    .IH_Div_Two{
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
    }

    .Buttons{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        gap: 10px;
    }

    .InternshipMVP {
        padding: 10px 20px 10px 20px;
        margin: 50px 0 150px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background-color: transparent;
        overflow: hidden;
    }

    .InternshipCTA {
        padding: 10px 20px 10px 20px;
        margin: 50px 0 150px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background-color: transparent;
        overflow: hidden;
    }

    .GhostCTA_LastBox{
        text-align: center;
    }

    .MainCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
    .GhostCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: transparent;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:white;
        display: inline;
        cursor: pointer;
    }

    .SectionH1 {
        color: #E76203;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }

    .SectionH2{
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
    }

    .CTAH1 {
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 3rem;
    }

    .CTAH2 {
        color: #E76203;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }

    .CTAH3 {
        margin:0 0 50px 0;
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        font-size: 2rem;
        font-weight: 500;
        line-height: 50px;
    }

    .CTANumber {
        display:none;
    }

    .InternshipCTABox {
        margin: 0 0 75px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: start;
    }
    .LogoDown {
        padding: 10px 0 0 0;
        margin: 10px 0 0 0;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .MobileNavBar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: fixed;
        align-items: center;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
        z-index: 1000;
        width: 100%;
        height: 160px;
    }
    .NavBar_P {
        margin: 0 0 0 0;
        color: #E76203;
        font-family: "Arial", Sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 3rem;
        text-align: center;
    }
    .RightScroll{
        display: none;
    }
}