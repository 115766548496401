
@media (min-width: 992px){
    .LogInH1 {
        text-align: center;
    }

    .InfoH2 {
        text-align: center;
    }

    .FutureP {
        text-align: center;
    }

    .LogInScreen {
        position: fixed;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        background-color: rgb(1, 51, 67);
    }

    .LogInBox {
        margin: 300px 0 300px 0;
    }
}
@media (max-width: 991px){
    .LogInH1 {
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3rem;    
    }

    .InfoH2 {
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
    }

    .FutureP {
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 3rem;
    }
    .FutureP1 {
        display: none;
    }

    .LogInScreen {
        position: fixed;
        inset: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: rgb(1, 51, 67);
    }

    .LogInBox {
        padding: 10px 10px 10px 10px;
        margin: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: start;
        background-color: transparent;
        overflow: hidden;    
    }  
    .RightScroll{
        display: none;
    }
}