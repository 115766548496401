@media (min-width: 992px){
    .PrivacyPolicy {
        padding: 10px 10px 10px 10px;
        margin: 100px 0 150px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;
        text-align: center;
        background-color: transparent;
        overflow: hidden;
    }

    .PolicyDiv {
        padding: 10px 10px;
        margin: 10px 200px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: start;
        overflow: hidden;
    }

    .Header {
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 3.6rem;
        font-weight: 600;
    }

    .SubSection {
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 50px;
    }

    .SectionDescription {
        color: #CECECE;
        font-family: "Arial", Sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2.2rem;
    }
    .MobileLogo {
        display: none;
    }
}
@media (max-width: 991px){
    .PrivacyPolicy {
        padding: 10px 10px 10px 10px;
        margin: 20px 0 90px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;
        text-align: center;
        background-color: transparent;
        overflow: hidden;
    }

    .PolicyDiv {
        padding: 10px 20px 10px 10px;
        margin: 0 20px 0 15px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: start;
        overflow: hidden;
    }

    .Header {
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3rem;
    }

    .SubSection {
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
    }

    .SectionDescription {
        color: #CECECE;
        font-family: "Arial", Sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2.3rem;
    }
    .MobileNavBar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: fixed;
        align-items: center;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
        z-index: 1000;
        width: 100%;
        height: 150px;
    }
    .NavBar_P {
        margin: 0 0 0 0;
        color: #E76203;
        font-family: "Arial", Sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 3rem;
        text-align: center;
    }
    .LogoDown {
        padding: 20px 0 0 0;
        margin: 0 0 0 0;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .RightScroll{
        display: none;
    }
}