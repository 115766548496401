@media (min-width: 992px ){
    .PRATSD2 {
        color:black;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 3rem;
        }
    .PRATSD2Topic {
            color:black;
            font-family: helvetica,arial;
            font-size: 2rem;
            font-weight: 600;
            line-height: 3rem;
            }
    .PRATSH1About {
        color:black;
        font-family: helvetica,arial;
        text-align: center;
        font-size: 3.6rem;
        line-height: 5rem;
        font-weight: 600;
        margin-bottom: 50px;
        }
    .CRTextBoxPRATS {
        text-align: left;
        max-width: 40%;
    }
    .ShareSpaceServices {
        text-align: center;
        max-width: 50%;
    }
    .ShareSpaceServicesContact {
        text-align: center;
    }
    .PRATSH1AboutContact {
        color:black;
        font-family: helvetica,arial;
        text-align: center;
        font-size: 2.5rem;
        line-height: 5rem;
        font-weight: 600;
        margin-bottom: 50px;
    }
} 
@media (max-width: 991px){
    .RightScroll {
        display: none;
    }
}