.MobileNavForm {
  padding: 10px 20px 10px 20px;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  background:linear-gradient(180deg, #e76203 18%, black 22%);
  background-repeat: no-repeat;
  }
.MobileLogoImage {
  margin: 0 0 30px 0;
  text-align: center;
  }
.MobileLogoTagLine {
  text-align: center;
  color: #dedede;
  font-family: "Arial", Sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3rem;
}
.MobileLinkNav{
  margin: 30px 0 10px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  color: white;
  font-family: "Arial", "Sans-serif";
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 3rem;
}
.BottomMobileNav {
  color: #dedede;
  font-size: 1rem;
  text-align: center;
}
a {
  text-decoration: none;
  color: inherit;
}
.active{
  text-decoration: underline;
}