@media (min-width: 992px) {
    .AboutHero {
        padding: 10px 10px 10px 10px;
        margin: 100px 0 150px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background-color: transparent;
        overflow: hidden;
    }

    .AHeroContainer1 {
        text-align: center;
    }

    .AHeroContainer2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .AboutMVP {
        padding: 10px 10px 10px 10px;
        margin: 0 0 150px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background-color: transparent;
        overflow: hidden;
    }

    .MVPContainer {
        padding: 10px;
        margin: 0 25% 0 25%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        text-align: start;
        min-width: 50%;
        max-width: 50%;
    }

    .AboutCTA {
        padding: 120px 20px 120px 20px;
        margin: 0 0 0 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px; 
        background-color: transparent;
        overflow: hidden;
    }
    .CTABox1 {
        padding: 0 20px 20px 20px;
        background-color: #001120;
        border: 2px solid #e76203;
        max-width: 600px;
        max-height: 600px;
        overflow:auto;
    }
    .CTABox {
        padding: 0 20px 20px 20px;
        background-color: #002347;
        border: 4px solid #e76203;
        max-width: 600px;
        max-height: 600px;
        overflow: auto;
    }

    .CTABoxCTA {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
    }

    .AboutInternshipFAQs {
        padding: 20px 10px 80px 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;
        background-color: #002347;
        border: 1px solid #e76203
    }

    .h1HERO {
        color:#FFFFFF;
        font-family: "Roboto",sans-serif;
        font-size: 3.6rem;
        font-weight: 600;
    }

    .h2HERO {
    color:#CBCBCB;
    font-family: "Roboto",sans-serif;
    font-size: 2rem;
    font-weight: 500;
    line-height: 50px;
    }
    .CTANudge{
        display: inline;
        margin: 5px 15px 0 0;
        font-size: 1.4rem;
        line-height: 50px;
        color: #b2b2b2;
    }

    .MainCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }

    .h2Label {
        color: #e76203;
        font-family: Arial,'Times New Roman';
        font-size: 3.6rem;
        font-weight: 600;
    }

    .pMVP {
        color: #dedede;
        font-family: Arial, 'Times New Roman';
        font-size: 2rem;
        font-weight: 400;
        line-height: 60px;
    }

    .CTABoxh2 {
        color: #FFFFFF;
        font-family: "Roboto", Sans-serif;
        font-size: 3rem;
        font-weight: 600;
        line-height: 70px;
    }

    .CTADescriptor {
        color: #CBCBCB;
        font-family: "Roboto", Sans-serif;
        font-size: 2rem;
        font-weight: 300;
        line-height: 50px;
    }

    .h2toh1 {
        color: #CBCBCB;
        font-family: "Roboto", Sans-serif;
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 60px;
    }

    .h2Descriptor {
        color: #dedede;
        font-family:  Arial, 'Times New Roman';
            font-size: 2rem;
        font-weight: 300;
        line-height: 50px;
    }

    .h3Descriptor {
        color: #E76203;
        font-family: "Roboto", Sans-serif;
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 60px;
    }
    .MobileLogo {
        display: none;
    }
    .MainCTA_End{
        display:none;
    }
    .CTANudge_End{
        display:none;
    }
}
@media (max-width: 991px) {
    .AboutHero {
        padding: 10px 20px 10px 20px;
        margin: 10px 0 80px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }

    .AHeroContainer1 {
        text-align: center;
    }

    .AHeroContainer2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .AboutMVP {
        padding: 10px 20px 10px 20px;
        margin: 0 0 90px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }

    .MVPContainer {
        margin: 30px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: start;
    }

    .AboutCTA {
        padding: 10px 20px 10px 20px;
        margin: 0 0 90px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: transparent;
        overflow: hidden;
    }
    .CTABox1 {
        padding: 10px 5px 35px 20px;
        margin: 20px 0;
        background-color: #001120;
        border: 2px solid #e76203;
        max-width: 700px;
        max-height: 700px;
        overflow:hidden;
    }
    .CTABox {
        padding: 10px 5px 35px 20px;
        margin: 30px 0;
        background-color: #002347;
        border: 4px solid #e76203;
        max-width: 700px;
        max-height: 700px;
        overflow: hidden;
    }

    .CTABoxCTA {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
    }

    .AboutInternshipFAQs {
        padding: 10px 20px 50px 20px;
        margin: 0 0 0 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;
        background-color: #002347;
    }

    .h1HERO {
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }

    .h2HERO {
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        text-align: start;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
    }
    .CTANudge{
        display: none;
    }

    .MainCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
    .MainCTA_End{
        padding: 10px;
        margin: 15px 0 40px 0;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        text-align: center;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }

    .h2Label {
        color: #e76203;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }

    .pMVP {
        color: #dedede;
        font-family: "Arial", Sans-serif;
        text-align: start;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
    }

    .CTABoxh2 {
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }

    .CTADescriptor {
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        text-align: start;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
    }

    .h2toh1 {
        color: #FFFFFF;
        font-family: "Arial", Sans-serif;
        text-align: center;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
    }

    .h2Descriptor {
        color: #CBCBCB;
        font-family: "Arial", Sans-serif;
        text-align: start;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3rem;
    }

    .h3Descriptor {
        color: #E76203;
        font-family: "Arial", Sans-serif;
        text-align: start;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 3rem;
    }
    .LogoDown {
        padding: 20px 0 0 0;
        margin: 20px 0 0 0;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .MobileNavBar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: fixed;
        align-items: center;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
        z-index: 1000;
        width: 100%;
        height: 100px;
    }
    .NavBar_P {
        margin: 0 0 0 0;
        color: #E76203;
        font-family: "Arial", Sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.5rem;
        text-align: center;
    }
    .CTANudge_End{
        display:none;
    }
    .RightScroll{
        display: none;
    }
}