@media (min-width:992px) {
    .CRHeroSection {
        padding: 50px 20px 50px 20px;
        margin: 0 0 150px 0;
        text-align: center;
    }
    .CRSubNavigation {
        margin: 80px 0 150px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
    }
    .CRSubNavText{
        color: white;
        font-family: Arial,'Times New Roman';
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .CRSubNavText a {
        text-decoration: none;
    }
    .CRH1{
        color:#e76203;
        font-family: helvetica,arial;
        font-size: 3.6rem;
        font-weight: 600;
        margin-bottom: 50px;
    }
    .CRH2{
        color: #e76203;
        font-family: Arial,'Times New Roman';
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 50px;
        margin-bottom: 70px;
    }
    .CRResources{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
    .CTRICRResource{
        margin: 30px 0 140px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        align-items: center;
        justify-content: center;
        gap:50px;
    }
    .CRH3 {
        color: White;
        font-family: Arial,'Times New Roman';
        font-size: 2rem;
        font-weight: 500;
    }
    .CRPDescriptor {
        margin: 0 0 50px 0;
        color: #cbcbcb;
        font-family: Arial,'Times New Roman';
        font-size: 2rem;
        font-weight: 400;
    }
    .TopScroll {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: fixed;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        z-index: 1000;
        width: 100px;
        height: 100vh;
    }
    .CRTextBox{
        text-align: left;
        max-width: 600px;
    }
    .CRCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
    .MobileLogo {
        display:none;
    }
    .MobileScrollUp{
        display:none;
    }
}

@media (max-width:991px) {
    .CRHeroSection {
        padding: 10px 20px 10px 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;        
        background-color: transparent;
        overflow: hidden;
    }
    .CRSubNavigation {
        margin: 80px 0 150px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
    }
    .CRSubNavText{
        color: white;
        font-family: Arial,'Times New Roman';
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .CRSubNavText a {
        text-decoration: none;
    }
    .CRH1{
        color:white;
        font-family: helvetica,arial;
        font-size: 3.6rem;
        font-weight: 600;
        margin-bottom: 50px;
        line-height: 4.2rem;
    }
    .CRH2{
        color: #e76203;
        font-family: Arial,'Times New Roman';
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 50px;
        margin-bottom: 70px;
    }
    .CRResources{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
    .CTRICRResource{
        padding: 0 20px 0 20px;
        margin: 30px 0 140px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        align-items: center;
        justify-content: center;
        gap:50px;
    }
    .CRH3 {
        color: White;
        font-family: Arial,'Times New Roman';
        font-size: 2rem;
        font-weight: 500;
    }
    .CRPDescriptor {
        margin: 0 0 50px 0;
        color: #cbcbcb;
        font-family: Arial,'Times New Roman';
        font-size: 1.6rem;
        font-weight: 400;
    }
    .TopScroll {
        display: none;
    }
    .CRTextBox{
        text-align: left;
        max-width: 600px;
    }
    .CRCTA{
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
    .MobileScrollUp{
        color: white;
        font-size: 1.5rem;
        font-family: Arial, 'Times New Roman' ;
        font-weight: 400;
    }
    .CRResourceImage {
        display: none;
    }
    .RightScroll{
        display: none;
    }
}