@media (min-width: 992px) {
    .PRATSHomePage {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-image: url("../Images/PRATS/PRATSBackground.webp");
        background-size: cover;
        background-position: center;
        z-index: 1000;
    }
    .PRATSTopNavigation {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: left;
        gap: 100px;
    }

    .PRATSHPHERO {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100vw;
        height: 100%;
        background: white;
        z-index: 1100;
        justify-content: center;
        align-items: center;
    }
    .PRATSHHero {
        margin: 0 0 20px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        min-width: 100%;
        align-items: center;
        text-align: center;
        z-index: 1100;
    }
    
    .PRATSHHEROTwo {
        margin: 0 0 150px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        z-index: 1200;
        
    }

    .PRATSH1{
        color:black;
        font-family: helvetica,arial;
        font-size: 3.6rem;
        line-height: 5rem;
        font-weight: 600;
        margin-bottom: 50px;
        max-width: 50%;
    }
    .PRATSD{
        color:black;
        font-family: helvetica,arial;
        font-size: 2rem;
        font-weight: 500;
        line-height: 3rem;
        max-width: 60%;
    }
    .PRATSDT{
        color:black;
        font-family: helvetica,arial;
        font-size: 2rem;
        font-weight: 500;
        line-height: 3rem;
    }
    .ShareSpace {
        margin: 0 0 50px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .NavBarDiv {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 40px;
    }
    .TopNavBarDiv {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 40px;
    }
    .PRATSTopLogo {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 10px; 
    }
    .PRATSNAV{
        padding: 10px;
        border: 2px solid black;
        border-radius: 10px;
        background: #1a9599;
        font-size: 1.8rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:#FFFFFF;
        display: inline;
        cursor: pointer;
    }
}
@media (max-width:991px) {
    .RightScroll{
        display: none;
    }
}
