@media (min-width: 992px) {
    .BlogSectionOption {
        padding: 10px;
        margin: 150px 0 150px 150px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;
        background-color: transparent;
        overflow: hidden;
        max-width: 80%;
    }
    .ImageBlog {
        margin: 100px 0 100px 120px;
    }
    .DivBlogDescriptor {
        padding: 20px;
        margin: 0 0 0 50px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 50%;
        align-self: center;
        text-align: start;
    }

    .BlogDescriptor {
        margin: 50px 0 80px 0;
        max-width: 95%;
        color: #dedede;
        font-family: Arial, 'Times New Roman';
        font-size: 1.5rem;
        line-height: 3rem;
        font-weight: 400;
        }
    
    .BlogH1 {
        margin: 150px 0 0 0 ;
        color:#FFFFFF;
        font-family: "Roboto",sans-serif;
        font-size: 3.6rem;
        font-weight: 600;
        text-align: center;
    }
    .BlogH2 {
        margin: 150px 0 150px 0 ;
        color:#FFFFFF;
        font-family: "Roboto",sans-serif;
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 5rem;
        text-align: center;
    }

    .PBlog {
        margin: 0 0 0 100px;
        color:#FFFFFF;
        font-family: "Roboto",sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 50px;
    }
    .BlogOption {
        margin: 80px 40px 0px 40px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        background-color: #001120;
        border: 2px solid #e76203;
        min-width: 60%;
        min-height: 500px;
        overflow:auto;
    }
    .MobileLogo {
        display: none;
    }
    .MainCTA_End{
        display:none;
    }
    .CTANudge_End{
        display:none;
    } 
    .BlogCTA {
        padding: 10px;
        border: 2px solid #e76203;
        border-radius: 10px;
        background: #e76203;
        font-size: 1.4rem;
        font-family: Helvetica, Arial;
        font-weight: 700;
        color:black;
        display: inline;
        cursor: pointer;
    }
}

@media (max-width: 991px) {
        .CTANudge_End{
            display:none;
        }
        .BlogSectionOption {
            padding: 20px;
            margin: 10px 0 80px 0;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            background-color: transparent;
            overflow: hidden;
        }
        .ImageBlog {
            display: none;
        }
        .DivBlogDescriptor {
            padding: 20px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-self: center;
            text-align: start;
        }
    
        .BlogDescriptor {
            color: #CBCBCB;
            margin: 60px 0 60px 0;
            font-family: "Arial", Sans-serif;
            text-align: start;
            font-size: 2.2rem;
            font-weight: 400;
            line-height: 3.5rem;
            }
        
        .BlogH1 {
            margin: 250px 0 80px 0;
            color: #FFFFFF;
            font-family: "Arial", Sans-serif;
            font-size: 3.2rem;
            font-weight: 600;
            line-height: 4rem;
            text-align: center;
        }
        .BlogH2 {
            margin: 80px 0 80px 0;
            color: #FFFFFF;
            font-family: "Arial", Sans-serif;
            font-size: 2.8rem;
            font-weight: 600;
            line-height: 4rem;
            text-align: center;
        }
    
        .PBlog {
            color:#FFFFFF;
            font-family: "Roboto",sans-serif;
            font-size: 3rem;
            font-weight: 600;
            line-height: 4rem;
            text-align: center;
        }
        .BlogOption {
            margin: 80px 0 0 0;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            background-color: #001120;
            border: 2px solid #e76203;
            overflow:auto;
        }
        .BlogCTA {
            padding: 10px;
            border: 2px solid #e76203;
            border-radius: 10px;
            background: #e76203;
            font-size: 1.4rem;
            font-family: Helvetica, Arial;
            font-weight: 700;
            color:black;
            display: inline;
            cursor: pointer;
        }
        .RightScroll{
            display: none;
        }
}